#contact {
    text-align: center;
    margin-bottom: 5vh;
}

.contact-icons-container {
    margin: 30px 0;
}

.contact-icon {
    font-size: 64px !important;
    color: white !important;
}

.contact-icon:hover {
    padding-top: -15px;
}