.about-me {
    width: 80vw;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    margin-top: 2.5vh;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.about-header {
    width: 85%;
}


.about-title {
    font-size: 72px;
    margin-bottom: 10px;
}

.about-subtitle {
    font-size: 32px;
    color: var(--text-unselected)
}

.about-techs {
    margin-top: 50px;
    width: 70%;
}

.techs-title {
    font-size: 32px;
    color: var(--text-unselected)
}

.techs-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1510px) {
    .about-me {
        flex-direction: column;
    }

    .about-techs {
        width: 70%;
    }

    .about-header {
        width: 100%;
    }
}

@media only screen and (max-width: 1100px) {
    .about-techs {
        width: 100%;
    }
}

@media only screen and (max-width: 800px) {
    .about-title {
        font-size: 56px;
    }
    .about-subtitle {
        font-size: 24px;
    }

    .techs-title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 600px) {
    .about-title {
        font-size: 48px;
    }
    .about-subtitle {
        font-size: 20px;
    }

    .techs-title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .about-title {
        font-size: 36px;
    }
    .about-subtitle {
        font-size: 16px;
    }

    .techs-title {
        font-size: 16px;
    }
}