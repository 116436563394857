nav {
    display: flex;
    margin-top: 4vh;
    color: var(--text-unselected);
    position: relative;
}

a {
    text-decoration: none;
    color: white;
}

.nav-name {
    font-size: 2rem;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 5vw;
}

.nav-name:hover {
    color: #FFF;
}

.nav-name::after {
    content: '';
    display: block;
    background-color: var(--hovered-text);
    width: 10%;
    height: 7px;
    margin-top: 5px;
    border-radius: 3px;
    transition: all 0.5s;
}

.nav-name:hover::after {
    width: 25%;
}

.nav-links {
    position: absolute;
    right: 5vw;
}

nav ul {
    list-style: none;
    text-align: right;
    display: inline-block;
    margin-top: 0;
}

nav ul li {
    display: inline;
    padding: 10px;
    font-size: 2rem;
    cursor: pointer;
}

nav ul li a {
    transition: all 0.75s;
}

nav ul li a:hover{
    color: #62cfde
}

@media only screen and (max-width: 750px) {
    .nav-name {
        font-size: 1.5rem;
    }

    nav ul li {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 500px) {
    .nav-name {
        font-size: 1rem;
        width: 30vw;
    }

    nav ul li {
        font-size: 1.25rem;
    }

    .nav-links {
        right: 2vw;
    }
}