.project {
    display: flex;
    width: 90%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}

.project-img-container {
    justify-self: center;
    flex: 15%;
    padding: 5%;
}

.project-img {
    width: 300px;
    height: 500px;
    border-radius: 25px;
    transition: all 0.5s;
}

.project-img:hover {
    margin-top: -20px;
    box-shadow: 0 4px black;
}

.project-info {
    flex: 65%;
    padding: 5%;
    padding-right: 0;
}

.project-info a {
    text-decoration: none;
    color: white;
}

.project-info p {
    font-size: 24px;
}

.project-title-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.project-title-link {
    align-self: center;
    margin-right: 50px;
}

.project-title {
    font-size: 48px;
    margin-top: 0;
}

.project-technologies {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.project-details-header {
    font-size: 32px;
    margin-bottom: 0;
}

.project-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

@media only screen and (max-width: 1200px) {
    .project-title {
        font-size: 42px;
    }
}

@media only screen and (max-width: 900px) {
    .project {
        flex-direction: column-reverse;
    }

    .project-img-container {
        flex: 100%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 700px) {
    .project-info p {
        font-size: 18px;
    }

    .project-img {
        width: 250px;
        height: auto;
    }
}

@media only screen and (max-width: 500px) {
    .project-title {
        font-size: 36px;
        justify-self: center;
    }

    .MuiSvgIcon-fontSizeLarge {
        font-size: 1.75rem !important;
    }

    .project-info a {
        font-size: 0.8em;
        text-align: center;
    }
}

@media only screen and (max-width: 400px) {
    .project-title-link {
        flex: 100%;
        text-align: left !important;
    }

    .project-title-container {
        flex-wrap: wrap;
    }
}