.tech-list-element {
    text-align: center;
    font-size: 1.6rem;
    background: var(--tech-card-background-unselected);
    color: var(--text-unselected);
    border-radius: 8px;
    box-shadow: -3px 4px black;
    margin: 5px;
    padding: 10px;
    cursor: default;
    flex: 0 0 20%;
}

@media only screen and (max-width: 1920px) {
    .tech-list-element {
        font-size: 1.5rem;
        flex: 0 0 27%;
    }
}

@media only screen and (max-width: 1700px) {
    .tech-list-element {
        font-size: 1.25rem;
        flex: 0 0 23%;
    }
}

@media only screen and (max-width: 1510px) {
    .tech-list-element {
        font-size: 2rem;
        flex: 0 0 20%;
    }
}

@media only screen and (max-width: 1300px) {
    .tech-list-element {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 700px) {
    .tech-list-element {
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: 550px) {
    .tech-list-element {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 375px) {
    .tech-list-element {
        font-size: 0.7rem;
    }
}