:root{
  --primary-background: #242121;
  --tech-card-background-unselected: #5e5d5d;
  --tech-card-background-selected: #33aab9;
  --proj-card-background-unselected: #368d99;
  --hovered-text: #50c7d6;
  --text-unselected: #DADADA;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 5px;
  margin-top: 0;
  font-family:  'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}