body{
  background-color: var(--primary-background);
  color: white;
}

.section-header {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}

.section-header::after {
  content: '';
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-color: var(--hovered-text);
  width: 5%;
  max-width: 300px;
  height: 7px;
  margin-top: 5px;
  border-radius: 3px;
  transition: all 0.5s;
}

.section-header:hover::after {
  width: 12.5%;
}

footer {
  text-align: center;
  margin-bottom: 3vh;
}

#return-link {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

#return-link::after {
  content: '';
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-color: var(--hovered-text);
  width: 2%;
  max-width: 300px;
  height: 5px;
  margin-top: 5px;
  border-radius: 3px;
  transition: all 0.5s;
}

#return-link:hover::after {
  width: 4%;
}

@media only screen and (max-width: 750px) {
  .section-header {
    font-size: 48px;
  }
}

@media only screen and (max-width: 500px) {
  .section-header {
    font-size: 36px;
  }
}